import React, { useState } from "react";
import { Container, Nav, Navbar, Col, Row } from "react-bootstrap";
import { Link, useNavigate, NavLink } from "react-router-dom";
import DstButton from "../DstButton/DstButton";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenuItem from "../DropdownMenuItem/DropdownMenuItem";
import CardHeader from "../CardHeader/CardHeader";
import "./DstNavbar.scss";

const navLinks = [
  { path: "/financement", label: "Financement" },
  { path: "/notre-ecole", label: "Notre école" },
];

const menuItemsFormations = [
  { title: "Automobile" },
  { title: "Nucléaire" },
  { title: "Férroviaire" },
  { title: "Aeronautique" },
  { title: "Navale" },
];

const NavLinkItem = ({
  path,
  label,
  isFirst,
  isLast,
}: {
  path: string;
  label: string;
  isFirst: boolean;
  isLast: boolean;
}) => (
  <NavLink
    className="d-flex align-items-center light-font py-1 px-3 justify-content-center text-decoration-none border-bottom-green position-relative h4"
    to={path}
  >
    {!isFirst && (
      <img
        src="/assets/images/decoration/vertical-line-header.svg"
        alt="vertical line decoration"
        className="verticale-header-line-left d-none d-lg-block"
      />
    )}
    {!isLast && (
      <img
        src="/assets/images/decoration/vertical-line-header.svg"
        alt="vertical line decoration"
        className="verticale-header-line-right d-none d-lg-block"
      />
    )}
    {label}
  </NavLink>
);

const DstNavbar = ({ buttonLink = "/rdv" }) => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("Automobile");
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuItemClick = (menuItem: string, keepOpen: boolean = false) => {
    setSelectedMenuItem(menuItem);
    if (!keepOpen) {
      setIsOpen(false);
    }
  };

  return (
    <Navbar expand="lg" id="navbar" className="dark-background sticky-top">
      <Container className="light-font position-relative">
        <Navbar.Brand className="me-5">
          <Link to="/">
            <img src="/assets/images/dst-ingenierie.svg" alt="DST Ingénierie" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="m-black p-2 rounded-0"
          id="navbar-toggle"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="flex-column">
          <Nav className="w-100">
            <Dropdown
              className="position-static"
              as={Nav.Item}
              show={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
            >
              <Dropdown.Toggle
                as="div"
                className="d-flex align-items-center light-font py-1 px-3 justify-content-center text-decoration-none border-bottom-green h4"
                role="button"
              >
                Formations
                <img
                  src="/assets/images/decoration/vertical-line-header.svg"
                  alt="vertical line decoration"
                  className="verticale-header-line-right d-none d-lg-block"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu megamenu w-100 p-2">
                <Row className="d-sm-flex m-0 py-0">
                  <Col className=" mb-3 xs-regular px-0">
                    <div className="all-tab">
                      {menuItemsFormations.map((menuItem) => (
                        <DropdownMenuItem
                          key={menuItem.title}
                          title={menuItem.title}
                          isActive={selectedMenuItem === menuItem.title}
                          clickFunction={() =>
                            handleMenuItemClick(menuItem.title, true)
                          }
                        />
                      ))}
                    </div>
                    <div className="text-center p-2">
                      <Link
                        to="/nos-formations"
                        className="text-decoration-none gray-font d-md-block"
                      >
                        Voir toutes les formations
                      </Link>
                    </div>
                  </Col>
                  {selectedMenuItem === "Automobile" && (
                    <Col md={9} lg={10} className="p-0">
                      <CardHeader
                        title="Software Developer"
                        link="/formation-software-developer"
                        image="/assets/images/icon/electric-car.svg"
                      />
                      <CardHeader
                        title="System Engineer"
                        link="/formation-system-engineer"
                        image="/assets/images/icon/book.svg"
                      />
                      <CardHeader
                        title="Lighting"
                        link="/formation-lighting"
                        image="/assets/images/icon/fuel.svg"
                      />
                      <CardHeader
                        title="Liaison au sol"
                        link="/formation-liaison-sol"
                        image="/assets/images/icon/work-women.svg"
                      />
                    </Col>
                  )}
                  {selectedMenuItem === "Nucléaire" && (
                    <Col md={9} lg={10} className="p-0">
                      <CardHeader
                        title="Ingénieur Sureté Nucléaire"
                        link="/formation-ingenieur-surete-nucleaire"
                        image="/assets/images/icon/write.svg"
                      />
                      <CardHeader
                        title="Ingénieur fonctionnement nucléaire"
                        link="/formation-ingenieur-fonctionnement-nucleaire"
                        image="/assets/images/icon/truck.svg"
                      />
                      <CardHeader
                        title="Ingénieur qualité nucléaire"
                        link="/formation-ingenieur-qualite-nucleaire"
                        image="/assets/images/icon/pont.svg"
                      />
                    </Col>
                  )}
                  {selectedMenuItem === "Férroviaire" && (
                    <Col md={9} lg={10} className="p-0">
                      <CardHeader
                        title="Ingénieur Système Ferroviaire"
                        link="/formation-ingenieur-systeme-ferroviaire"
                        image="/assets/images/icon/setting-square.svg"
                      />
                    </Col>
                  )}
                  {selectedMenuItem === "Aeronautique" && (
                    <Col md={9} lg={10} className="p-0">
                      <CardHeader
                        title="Concepteur systèmes embarqués"
                        link="/formation-concepteur-systemes-embarques"
                        image="/assets/images/icon/rule.svg"
                      />
                      <CardHeader
                        title="Technicien banc d’essai"
                        link="/formation-technicien-banc-essai"
                        image="/assets/images/icon/ampermeter.svg"
                      />
                    </Col>
                  )}
                  {selectedMenuItem === "Navale" && (
                    <Col md={9} lg={10} className="p-0">
                      <CardHeader
                        title="Dessinateur construction navale"
                        link="/formation-dessinateur-construction-navale"
                        image="/assets/images/icon/batterie.svg"
                      />
                      <CardHeader
                        title="Dessinateur projeteur tuyauterie"
                        link="/formation-dessinateur-projeteur-tuyauterie"
                        image="/assets/images/icon/car.svg"
                      />
                    </Col>
                  )}
                  <Link
                    to="/nos-formations"
                    className="text-decoration-none gray-font text-center d-md-none"
                  >
                    Voir toutes les formations
                  </Link>
                </Row>
              </Dropdown.Menu>
            </Dropdown>

            {navLinks.map((link, index) => (
              <NavLinkItem
                key={link.path}
                {...link}
                isFirst={index === 0}
                isLast={index === navLinks.length - 1}
              />
            ))}
          </Nav>
          <div className="d-flex justify-content-center">
            <DstButton
              btnClass="d-block d-lg-none"
              value="Prendre RDV"
              clickFunction={() => navigate(buttonLink)}
            />
          </div>
        </Navbar.Collapse>
        <DstButton
          btnClass="d-none d-lg-block"
          value="Prendre RDV"
          clickFunction={() => navigate(buttonLink)}
        />
      </Container>
    </Navbar>
  );
};

export default DstNavbar;
